export function VSCodeIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      fill="currentColor"
      className={className}
    >
      <title>Visual Studio Code Icon</title>
      <path d="M23.15 2.587L18.21.21a1.494 1.494 0 0 0-1.705.29l-9.46 8.63-4.12-3.128a.999.999 0 0 0-1.276.057L.327 7.261A1 1 0 0 0 .326 8.74L3.899 12 .326 15.26a1 1 0 0 0 .001 1.479L1.65 17.94a.999.999 0 0 0 1.276.057l4.12-3.128 9.46 8.63a1.492 1.492 0 0 0 1.704.29l4.942-2.377A1.5 1.5 0 0 0 24 20.06V3.939a1.5 1.5 0 0 0-.85-1.352zm-5.146 14.861L10.826 12l7.178-5.448v10.896z" />
    </svg>
  );
}

export function DiscordIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      fill="currentColor"
      className={className}
    >
      <title>Discord Icon</title>
      <path d="M20.317 4.369a19.791 19.791 0 00-4.885-1.508.07.07 0 00-.073.035 13.76 13.76 0 00-.601 1.233 19.601 19.601 0 00-5.865 0 13.79 13.79 0 00-.602-1.233.073.073 0 00-.073-.035c-1.632.321-3.212.833-4.886 1.508a.061.061 0 00-.03.025c-3.231 4.832-4.106 9.526-3.669 14.192a.084.084 0 00.031.058 19.993 19.993 0 005.995 3.064.073.073 0 00.079-.027 13.815 13.815 0 001.224-1.998.07.07 0 00-.038-.098 11.72 11.72 0 01-1.708-.82.07.07 0 01-.007-.118c.115-.085.23-.17.34-.259a.07.07 0 01.071-.01 13.348 13.348 0 0012.076 0 .07.07 0 01.072.01c.111.088.225.173.34.259a.07.07 0 01-.006.118 11.551 11.551 0 01-1.709.82.07.07 0 00-.037.099 13.548 13.548 0 001.224 1.998.073.073 0 00.079.027 19.986 19.986 0 005.996-3.064.084.084 0 00.031-.058c.556-5.675-.883-10.32-3.668-14.192a.062.062 0 00-.031-.025zM8.339 15.79c-1.181 0-2.156-1.085-2.156-2.419 0-1.334.96-2.419 2.156-2.419 1.197 0 2.166 1.085 2.156 2.419 0 1.334-.96 2.419-2.156 2.419zm7.319 0c-1.182 0-2.156-1.085-2.156-2.419 0-1.334.96-2.419 2.156-2.419 1.197 0 2.166 1.085 2.156 2.419 0 1.334-.96 2.419-2.156 2.419z"/>
    </svg>
  );
}

export function EclipseIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      fill="currentColor"
      className={className}
    >
      <title>Eclipse Icon</title>
      <path d="M 19.509766 4.3144531 C 9.5887656 5.9844531 2 14.612 2 25 C 2 35.388 9.5887656 44.015547 19.509766 45.685547 C 11.055766 42.615547 5 34.502 5 25 C 5 15.498 11.055766 7.3844531 19.509766 4.3144531 z M 27 5 C 17.684 5 9.87925 11.379 7.65625 20 L 46.345703 20 C 44.121703 11.379 36.316 5 27 5 z M 7.2480469 22 C 7.1490469 22.657 7.0847812 23.324 7.0507812 24 L 46.949219 24 C 46.915219 23.324 46.851953 22.657 46.751953 22 L 7.2480469 22 z M 7.0507812 26 C 7.0847812 26.676 7.1490469 27.343 7.2480469 28 L 46.751953 28 C 46.850953 27.343 46.915219 26.676 46.949219 26 L 7.0507812 26 z M 7.65625 30 C 9.87925 38.621 17.684 45 27 45 C 36.316 45 44.12075 38.621 46.34375 30 L 7.65625 30 z"></path>
    </svg>
  );
}

export function BlenderIcon({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" fill="currentColor" className={className}>
      <title>Blender Icon</title>
      <path d="M18.535 1.007c-.932-.621-2.047-.621-2.979 0l-2.122 1.414h-4.996l-2.122-1.414c-.932-.621-2.047-.621-2.979 0L2.057 4.993c-.621.932-.621 2.047 0 2.979l2.122 1.414h4.996l2.122-1.414c.932-.621 2.047-.621 2.979 0l2.122 1.414h4.996l-2.122-1.414c-.932-.621-2.047-.621-2.979 0zm-3.473 7.778v8.21l-3.474-2.314h-1.004l-3.474 2.314v-8.21h-2.008v-1.004h8.959v1.004z"/>
    </svg>
  );
}

export function JupyterIcon({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" fill="currentColor" className={className}>
      <title>Jupyter Notebook Icon</title>
      <path d="M4 4h16v16H4zm8 8a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 12a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM12 12a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM16 12a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
    </svg>
  );
}
