import { FiGithub, FiInstagram, FiLinkedin, } from "react-icons/fi";
import { MdEmail } from "react-icons/md";
import { motion } from "framer-motion";

const socialLinks = [
  {
    id: 2,
    icon: <FiGithub />,
    url: "https://www.github.com/VanshajR",
    className: "github-icon"
  },
  {
    id: 4,
    icon: <FiLinkedin />,
    url: "https://www.linkedin.com/in/vanshajraghuvanshi/",
    className: "linkedin-icon"
  },
  {
    id: 5,
    icon: <MdEmail />,
    url: "mailto:vanshajraghuvanshi@gmail.com",
    className: "email-icon"
  },
  {
    id: 6,
    icon: <FiInstagram />,
    url: "https://www.instagram.com/vanshajr_0410",
    className: "instagram-icon"
  },
];

const AppFooter = () => {
  return (
    <div className="container mx-auto">
      <div className="pt-20 sm:pt-30 pb-8 border-t-2 dark:border-neutral-600 border-neutral-300">
        {/* Footer social links */}
        <div className="flex flex-col justify-center items-center mb-12 sm:mb-28">
          <p className="text-3xl font-medium sm:text-4xl text-[#DB6B31] dark:text-[#DB6B31] mb-5">
            Connect With Me
          </p>
          <ul className="flex gap-4 sm:gap-8">
            {socialLinks.map((link) => (
              <motion.a
                whileHover={{ scale: 1.1 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                href={link.url}
                target="__blank"
                key={link.id}
                className={`dark:bg-[#111111] hover:bg-neutral-200 dark:hover:bg-[#1b1b1b] cursor-pointer rounded-xl bg-gray-100 shadow-sm p-4 border dark:border-white/5 border-black/5 ${link.className}`}
              >
                <i className="text-xl sm:text-2xl md:text-3xl">{link.icon}</i>
              </motion.a>
            ))}
          </ul>
        </div>
        <br />
      </div>
    </div>
  );
};

export default AppFooter;
