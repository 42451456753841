import React from "react";
import Section from "../Common/Section";
import { motion } from "framer-motion";
import Quote from "../Common/Quote";
import FeaturedCard from "../FeatureCard/FeaturedCard";
import {FaBriefcase, FaHandHoldingMedical, FaLaptop, FaLaptopMedical} from "react-icons/fa";
// import { faHouseMedical } from '@fortawesome/free-solid-svg-icons';
// import { FaGraduationCap, FaBriefcase, FaHandHoldingMedical, FaLaptop } from "react-icons/fa";
import RightSvg from "../../assets/svg/right-pattern.svg";
import { Reveal } from "../Common/Reveal";
import BlurCircles from "../Common/BlurCircles";
import tietIcon from "../../assets/yellow/tiet.png";
import dpsIcon from "../../assets/yellow/dps_logo.png";


const About = () => {
  return (
    <Section
      id="about"
      title="Overview"
      subtitle="Python Developer"
      className="relative"
    >
      <img
        src={RightSvg}
        alt=""
        className="absolute hidden right-0 bottom-[40%] w-2/12 max-w-xs md:block"
        loading="lazy"
        height={700}
        width={320}
      />
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 1.5 }}
        className="flex flex-col min-h-fit text-center xl:text-left xl:flex-row max-w-7xl px-4 mx-auto items-center md:pt-0 md:pb-16 md:mx-20 z-10"
      >
        <motion.div
          initial={{ x: 50, opacity: 0 }}
          transition={{ duration: 1 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          className="space-y-5 ml-0  xl:pt-0 xl:ml-20 md:pt-10"
          style={{ userSelect: 'none' }}
        >
          <h4  className="text-2xl font-medium text-[#DB6B31] dark:text-[#DB6B31]">
            <Reveal width="100%">Here's a Little Background.</Reveal>
          </h4>
          <div className="bg-white dark:bg-[#101111] p-5 rounded-3xl shadow-lg shadow-gray-400/50 dark:shadow-black/30 ">
            <p className="text-base font-light">
              Greetings, I am <span className="font-semibold">Vanshaj Raghuvanshi</span>{" "}
              , and I specialize in{" "}
              <span className="font-semibold">Python development</span>. With a proficient command of Python, I am actively seeking new opportunities to leverage and enhance my expertise in software development, particularly in the realms of AI and machine learning applications.
            </p>
            <form action="https://drive.google.com/drive/folders/1_9IdKdm6XsZQZp2hjgY3FZuNLJIsOL9C?usp=drive_link">
          <button type="link" className="bg-[#DB6B31] dark:bg-[#DB6B31] text-white dark:text-white px-5 py-2 rounded-lg shadow-lg hover:shadow-xl transition duration-200 mt-5" onClick="visitResume();">Resume</button>
</form>
          </div>

          <h4 className="text-2xl font-medium text-[#DB6B31] dark:text-[#DB6B31]">
            <Reveal width="100%">Work Experience</Reveal>
          </h4>


          <FeaturedCard
            icon={
              <div className="rounded-xl bg-[#DB6B31] p-3.5 dark:bg-[#DB6B31]">
                <FaHandHoldingMedical className="h-6 w-6 text-white" />
              </div>
            }
            title="May 2024 - Current"
            desc="Mental Health Ambassador, Technical Team Member at Thapar Institute Counselling Cell, TIET."
          />

          <FeaturedCard
            icon={
              <div className="rounded-xl bg-[#DB6B31] p-3.5 dark:bg-[#DB6B31]">
                <FaLaptopMedical className="h-6 w-6 text-white" />
              </div>
            }
            title="June 2024 - July 2024"
            desc="Summer Intern (AI) at Meril."
          />

          <FeaturedCard
            icon={
              <div className="rounded-xl bg-[#DB6B31] p-3.5 dark:bg-[#DB6B31]">
                <FaBriefcase className="h-6 w-6 text-white" />
              </div>
            }
            title="June 2024 - July 2024"
            desc="Artificial Intelligence Intern at CodeClause."
          />

          <FeaturedCard
            icon={
              <div className="rounded-xl bg-[#DB6B31] p-3.5 dark:bg-[#DB6B31]">
                <FaBriefcase className="h-6 w-6 text-white" />
              </div>
            }
            title="June 2024 - July 2024"
            desc="Python Development Intern at Techplement."
          />

          <FeaturedCard
            icon={
              <div className="rounded-xl bg-[#DB6B31] p-3.5 dark:bg-[#DB6B31]">
                <FaLaptop className="h-6 w-6 text-white" />
              </div>
            }
            title="May 2023 - May 2024"
            desc="Technical Head at Backslash Computing Society, Thapar Institute of Engineering and Technology."
          />

          <h4 className="text-2xl font-medium text-[#DB6B31] dark:text-[#DB6B31] font-medium">
            <Reveal width="100%">Education</Reveal>
          </h4>

          <FeaturedCard
            icon={
              <div className="rounded-xl bg-[#DB6B31] p-3.5 dark:bg-[#DB6B31]">
                <img src={tietIcon} alt="TIET" className="h-6 w-6 text-white" />
              </div>
            }
            title="September 2022 - Expected 2026"
            desc="BE Computer Science Engineering, Thapar Institute of Engineering and Technology."
          />
          <FeaturedCard
            icon={
              <div className="rounded-xl bg-[#DB6B31] p-3.5 dark:bg-[#DB6B31]"> 
                <img src={dpsIcon} alt="DPS" className="h-6 w-6 text-white" />
              </div>
            }
            title="July 2021"
            desc="AISSCE, CBSE Board, 12th Standard with 98% marks in PCM and Computer Science, and was a Gold Medalist."            
          />
          <FeaturedCard
            icon={
              <div className="rounded-xl bg-[#DB6B31] p-3.5 dark:bg-[#DB6B31]">
                {/* <FaGraduationCap className="h-5 w-5 text-white" /> */}
                <img src={dpsIcon} alt="DPS" className="h-6 w-6 text-white" />
              </div>
            }
            title="May 2019"
            desc="AISSE, CBSE Board, 10th Standard with 96% marks."
          />
          
        </motion.div>
      </motion.div>

      <div className="relative">
        <motion.div
          initial={{ opacity: 0 }}
          transition={{ duration: 2 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
        >
          <BlurCircles />
        </motion.div>

        <motion.div
          initial={{ x: -50, opacity: 0 }}
          transition={{ duration: 1 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          className="relative pt-10 md:pt-5 lg:pt-0"
        >
          <Quote />
        </motion.div>
      </div>
    </Section>
  );
};

export default About;
