import React from "react";
import Label from "./Label";
import { motion } from "framer-motion";
import { Reveal } from "../Common/Reveal";

const Details = () => {
  return (
    <div className="flex flex-col text-left pt-5">
      <motion.h2
        initial={{ x: -50, opacity: 0 }}
        transition={{ duration: 1 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="font-medium text-lg text-[#DB6B31] dark:text-[#DB6B31] mt-5 mb-1"
      >
        <Reveal width="100%">Programming Languages</Reveal>
      </motion.h2>
      <motion.div
        initial={{ x: -50, opacity: 0 }}
        transition={{ duration: 1 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="flex flex-wrap gap-2"
      >
        <Label indicator={85}>Python</Label>
        <Label indicator={80}>C/C++</Label>
        <Label indicator={50}>Java</Label>
        <Label indicator={20}>R</Label>
        <Label indicator={50}>JavaScript</Label>
        <Label indicator={80}>SQL</Label>
        <Label indicator={80}>MATLAB</Label>
        <Label indicator={70}>PL/SQL</Label>
      </motion.div>

      <motion.h2
        initial={{ x: -50, opacity: 0 }}
        transition={{ duration: 1 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="font-medium text-lg text-[#DB6B31] dark:text-[#DB6B31] mt-5 mb-1"
      >
        <Reveal width="100%">Technologies</Reveal>
      </motion.h2>
      <motion.div
        initial={{ x: -50, opacity: 0 }}
        transition={{ duration: 1 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="flex flex-wrap gap-2"
      >
        <Label indicator={70}>Django</Label>
        <Label indicator={40}>TensorFlow</Label>
        <Label indicator={40}>PyTorch</Label>
        <Label indicator={30}>nltk</Label>
        <Label indicator={40}>Keras</Label>
        <Label indicator={80}>OpenCV</Label>
        <Label indicator={80}>Tkinter</Label>
        <Label indicator={60}>Mediapipe</Label>
        <Label indicator={80}>NumPy</Label>
        <Label indicator={80}>Pandas</Label>
        <Label indicator={80}>Nginx</Label>
        <Label indicator={60}>MySQL</Label>
        <Label indicator={60}>PostgreSQL</Label>
        <Label indicator={50}>Jupyter</Label>
        <Label indicator={40}>BeautifulSoup4</Label>
      </motion.div>

      <motion.h2
        initial={{ x: -50, opacity: 0 }}
        transition={{ duration: 1 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="font-medium text-lg text-[#DB6B31] dark:text-[#DB6B31] mt-5 mb-1"
      >
        <Reveal width="100%">Softwares and Tools</Reveal>
      </motion.h2>
      <motion.div
        initial={{ x: -50, opacity: 0 }}
        transition={{ duration: 1 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="flex flex-wrap gap-2"
      >
        <Label indicator={80}>Git</Label>
        <Label indicator={90}>GitHub</Label>
        <Label indicator={100}>VS Code</Label>
        <Label indicator={70}>PyCharm</Label>
        <Label indicator={70}>AWS EC2</Label>
        <Label indicator={70}>AutoCAD</Label>
        <Label indicator={95}>Discord</Label>
        <Label indicator={25}>Blender</Label>
      </motion.div>

      <motion.h2
        initial={{ x: -50, opacity: 0 }}
        transition={{ duration: 1 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="font-medium text-lg text-[#DB6B31] dark:text-[#DB6B31] mt-5 mb-1"
      >
        <Reveal width="100%">Opearting Systems</Reveal>
      </motion.h2>
      <motion.div
        initial={{ x: -50, opacity: 0 }}
        transition={{ duration: 1 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="flex flex-wrap gap-2"
      >
        <Label indicator={90}>Windows</Label>
        <Label indicator={80}>Ubuntu</Label>
        <Label indicator={70}>Kali Linux</Label>
        <Label indicator={80}>Arch Linux</Label>
      </motion.div>
    </div>
  );
};

export default Details;
