function Quote() {
  return (
    <div className="flex duration-300">
      <blockquote className="flex gap-2 pt-2 text-2xl text-slate-800 md:text-3xl lg:pt-5 lg:text-5xl dark:text-slate-400">
        <span className="flex flex-col">
          <span className="leading-[1.15] text-left">
            We are not defined by our{" "}
          </span>
          <span className="flex items-center gap-2 leading-[1.15] lg:gap-4">
            <span>
              <strong className="font-extrabold text-[#DB6B31] dark:text-[#DB6B31]">
                past
              </strong>{" "}
              but by the{" "}
              <strong className="font-extrabold text-[#DB6B31] dark:text-[#DB6B31]">
                choices{" "}
              </strong>
            </span>
          </span>
          <span className="leading-[1.15]">
            we make in the{" "}
            <strong className="relative font-extrabold text-[#DB6B31] dark:text-[#DB6B31]">
              <span className="font-extrabold text-[#DB6B31] dark:text-[#DB6B31]" />
              present.
            </strong>
          </span>
        </span>
      </blockquote>
    </div>
  );
}

export default Quote;
