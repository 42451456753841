import React from "react";
import { motion } from "framer-motion";
import { fadeTop, motionStep } from "./Motion";
import Featured from "./Featured";
import Section from "../Common/Section";
import ProjectCard from "./ProjectCard";
import RightSvg from "../../assets/svg/right-pattern.svg";

export const projectsData = [
  {
    id: 1,
    thumbnail:
      "https://res.cloudinary.com/dnciaoigz/image/upload/v1720448613/WhatsApp_Image_2024-07-08_at_19.52.33_282e22fc_ieyuto.jpg",
    title: "Object Detection",
    description:
      "A Python GUI application which identifies objects of the COCO dataset for both live feed and images.",
    code: "https://github.com/VanshajR/ObjectDetection",
    tech: ["Python", "CustomTkinter", "OpenCV"],
    featured: false,
  },
  {
    id: 2,
    thumbnail:
      "https://res.cloudinary.com/dnciaoigz/image/upload/v1710926784/Screenshot_2024-03-20_145529_wfxto8.png",
    title: "Zenith",
    description:
      "Website for a 3-day tech event organised by Backslash Computing Society, TIET, involving a cryptic hunt.",
    code: "https://github.com/VanshajR/ZenithCryptic",
    tech: ["HTML/CSS", "Express", "Node", "MongoDB"],
    featured: false,
  },
  {
    id: 3,
    thumbnail:
      "https://res.cloudinary.com/dnciaoigz/image/upload/v1720448742/Screenshot_2024-07-08_195452_qgqnfj.png",
    title: "Facial Recognition",
    description:
      "A Python application which allows for training of a TensorFlow/Keras model and detect faces in the trained dataset; works for both live feed and images.",
    code: "https://github.com/VanshajR/FacialRecognition",
    tech: ["Python", "CustomTkinter", "OpenCV", "TensorFlow", "Keras","NumPy"],
    featured: false,
  },
  {
    id: 4,
    thumbnail: "https://res.cloudinary.com/dnciaoigz/image/upload/v1710926227/appls_rtgpzb.jpg",
    title: "Linear Equations Solver",
    description: "Android app that can solve a system of linear equations upto 20 variables.",
    code: "https://github.com/VanshajR/LinearEquationSolver",
    tech: ["Java", "XML", "Android Studio"],
    featured: false,
  },
  {
    id: 5,
    thumbnail:
      "https://res.cloudinary.com/dnciaoigz/image/upload/v1714848671/Screenshot_2024-05-05_001753_mmi8dg.png",
    title: "Healix",
    description:
      "A Python GUI application with a robust PostgreSQL backend which allows managing patient/staff records and appointments.",
    code: "https://github.com/VanshajR/Healix",
    tech: ["Python", "CustomTkinter", "PostgreSQL", "PL/pgSQL"],
    featured: false,
  },
  
  {
    id: 6,
    thumbnail:
      "https://res.cloudinary.com/dnciaoigz/image/upload/v1720448741/Screenshot_2024-07-08_195520_llkphc.png",
    title: "Chat Bot",
    description:
      "A chatbot trained using PyTorch and NLP techniques that answers questions about me. Not very advanced, but it's a start.",
    code: "https://github.com/VanshajR/ChatBot",
    tech: ["Python", "PyTorch", "NLP(nltk)", "NumPy"],
    featured: false,
  },
  {
    id: 7,
    thumbnail: "https://res.cloudinary.com/dnciaoigz/image/upload/v1710927181/Screenshot_2024-03-20_150236_uooysj.png",
    title: "Physics Experiments Solver ",
    description:
      "Python GUI application that solves and visualises specific experiments in physics.",
    code: "https://github.com/VanshajR/Physics-Experiment-Result-Visualiser",
    tech: ["Python", "Tkinter", "Matplotlib"],
    featured: false,
  }
];

const Projects = () => {
  return (
    <Section
      id="projects"
      title="Projects"
      subtitle="Some of the projects I have worked on:"
      className="relative"
    >
      <div className="space-y-5 lg:space-y-10 px-5 md:px-10 lg:px-20 2xl:px-40">
        {/* Right SVG */}
        <img
          src={RightSvg}
          alt=""
          className="absolute hidden right-0 bottom-2/4 w-2/12 max-w-xs md:block"
          loading="lazy"
          height={700}
          width={320}
        />
        {projectsData
          .filter((e) => e.featured === true)
          .map((e, i) => (
            <motion.div key={i} variants={fadeTop} {...motionStep}>
              <Featured
                live={e.live}
                thumbnail={e.thumbnail}
                code={e.code}
                title={e.title}
                description={e.description}
                tech={e.tech}
                secondary={i % 2 === 0 ? false : true}
              />
            </motion.div>
          ))}
      </div>
      <div className="grid grid-cols-8 2xl:grid-cols-12 gap-6 gap-y-8 my-10 px-5 md:px-10 lg:px-20 2xl:px-40">
        {projectsData
          .filter((e) => e.featured !== true)
          .map((e, i) => (
            <ProjectCard
              live={e.live}
              thumbnail={e.thumbnail}
              code={e.code}
              title={e.title}
              description={e.description}
              tech={e.tech}
              key={i}
            />
          ))}
      </div>
      <span>
        For More Projects visit my{" "}
        <a
          href="https://github.com/VanshajR"
          style={{ fontWeight: "bold", color: "red" }}
        >
          GitHub
        </a>
      </span>
    </Section>
  );
};

export default Projects;
